import React from "react";

// Components
import Container from "@material-ui/core/Container";
import PostList from "../components/Blog/PostList";
import SEO from "../components/SEO";
import TagBar from "../components/Blog/TagBar";

// Styles
import CssBaseline from "@material-ui/core/CssBaseline";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import blogTheme from "../../config/blog-theme";

// Helpers
import { graphql } from "gatsby";

// Types
import { PostCardInfo } from "../components/Blog/PostCard";

export type IndexProps = {
  readonly data: {
    allMarkdownRemark: {
      edges: {
        node: PostCardInfo;
      }[];
    };
  };
};

const useClasses = makeStyles((theme) => ({
  postList: {
    marginBottom: theme.spacing(10),
  },
}));

const Index = ({ data }: IndexProps): JSX.Element => {
  const { edges } = data.allMarkdownRemark;
  const classes = useClasses();

  return (
    <>
      <SEO title="eKee - Blog" />
      <ThemeProvider theme={blogTheme}>
        <CssBaseline />
        {/** Needed to override the background color */}
        <Container component="main" maxWidth="lg">
          <TagBar />
          <PostList className={classes.postList} posts={edges} />
        </Container>
      </ThemeProvider>
    </>
  );
};

export default Index;

Index.propTypes = {};

export const query = graphql`
  query blogPosts($tags: [String]) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: $tags }, published: { ne: false } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 110)
          frontmatter {
            title
            path
            tags
            date(formatString: "MM.DD.YYYY")
            description
            author {
              firstname
              lastname
              jobTitle
              linkedin
              github
              twitter
              profilePic {
                childImageSharp {
                  fixed(width: 44, height: 44) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
