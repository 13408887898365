import React from "react";

// Components
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";

// Helpers
import upperfirst from "lodash.upperfirst";

// Styles
import clsx from "clsx";
import makeStyles from "@material-ui/core/styles/makeStyles";

export type TagBarProps = {
  className?: string;
};

const tags = [
  { name: "all", href: "/blog" },
  { name: "engineering", href: "/blog/engineering" },
  { name: "product", href: "/blog/product" },
  //  { name: "management", href: "/blog/management" },
] as const;

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    justifyContent: "center",

    // Handle smalle devices
    display: "flex",
    overflowX: "auto",

    // Permit to have a bigger bar
    padding: theme.spacing(2),
  },
  link: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    transition: "color 0.5s ease",

    // Remove underline
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  active: {
    color: "#c14926",
    borderBottomStyle: "solid",
    borderBottomColor: "#c14926",
    borderBottomWidth: 2,
  },
}));

export const TagBar = ({ className }: TagBarProps): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      {tags.map((t) => (
        <Typography
          color="textPrimary"
          component={Link}
          key={t.name}
          className={clsx(classes.link, className)}
          activeClassName={classes.active}
          to={t.href}
        >
          {upperfirst(t.name)}
        </Typography>
      ))}
    </section>
  );
};

export default TagBar;
