import { grey } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import baseTheme from "./theme";

const fontFamily = "Inter, -apple-system, BlinkMacSystemFont, sans-serif";

const theme = createMuiTheme({
  ...baseTheme,
  overrides: {
    ...baseTheme.overrides,
    MuiCssBaseline: {
      "@global": {
        html: {
          backgroundColor: grey["100"],
        },
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    background: {
      default: grey["100"],
      paper: grey["100"],
    },
    text: {
      primary: "rgb(38, 42, 62)",
    },
  },
  typography: {
    ...baseTheme.typography,
    fontFamily,
    h1: {
      fontFamily,
    },
    h2: {
      fontFamily,
    },
    h3: {
      fontFamily,
    },
    h4: {
      fontFamily,
    },
    h5: {
      fontFamily,
      fontSize: 20,
    },
    h6: {
      fontFamily,
    },
    body1: {
      fontFamily,
      fontSize: 16,
    },
    body2: {
      fontFamily,
    },
  },
});

export default theme;
